import React, {useState,useEffect,useMemo} from 'react';

import Loader from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import { useSelector, useDispatch } from 'react-redux'
import { deleteFeed, postFeed, getFeedPosts } from '../Redux/SocialPosting/SocialPostingActions'
import { uploadImage } from "../Redux/Gallery/GalleryActions";
import { Button, Pagination } from 'react-bootstrap';
import { useLocation, Link } from 'react-router-dom';
import DatePicker from "react-datepicker";
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import { config } from '../Redux/config';

function AccountFeedPosts() {
  // store data access start
  const socialPosting = useSelector(state =>state.SocialPosting);
  const galleryData = useSelector(state => state.Gallery);
  // store data access End
  const dispatch = useDispatch()  // for accessing the redux function
  // const { accountId, profile_picture} = useParams();
  const location = useLocation();
  const { accountId, profile_picture, accountName } = location.state; // Access passed state
  // component all states define start
  const [feedPosts, setFeedPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [message, setMessage] = useState();
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [images, setImages] = useState([]);
  const [index, setIndex] = useState(0);
  const [scheduleDate, setScheduleDate] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [scheduleTime, setScheduleTime] = useState("00:00");
  const [loadedMessage, setLoadedMessage] = useState(true);
  const [isLoaded, setLoaded] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  
  var mess = "";
  const [inputValues, setInputValues] = useState({
          message:"",
          scheduleDate: null,
          images:[]
  })
  const [error,setError] = useState(null);
  // fetch allexams api hook start
  // useEffect(() =>{
  //   console.log(item.accountId)
  //    dispatch(getFeedPosts(item.accountId, currentPage))
  // },[dispatch])
  useEffect(() => {
      dispatch(getFeedPosts(accountId, currentPage));
    
  }, [dispatch, accountId, currentPage]);
  // fetch allexams api hook End

  useMemo(() =>{
        if(galleryData && galleryData.upload_image && galleryData.upload_image.imageId) {
          setLoaded(true);
          setErrorMessage(null);
          document.getElementById(`socialImageId_${index}`).style.backgroundImage = `url(${galleryData.upload_image.endpoint})`;
          document.getElementById(`socialImageId_${index}`).style.backgroundSize = 'cover';
          images[index] = galleryData.upload_image.endpoint;//imageId
        }
      },[galleryData])

  const addImage = () => {
    setImages([...images, ""]);
  };

  const removeImage = () => {
    if (images.length > 0) {
      setImages(images.slice(0, -1));
    }
  };

  const format = 'HH:mm';
  
  const now = moment().hour(0).minute(0);

  const uploadFunction = (ind) => {
        let input = document.createElement('input');
        input.type = 'file';
        input.onchange = _ => {
          if (input.files[0]) {
            // Validate file type
            if (input.files[0].type !== 'image/jpeg') {
              setErrorMessage('Only JPG images are allowed.');
            } else {
               setLoaded(false);
               setIndex(ind);
               dispatch(uploadImage(input.files[0]));
            }
          }    
        };
        input.click(); 
      }

  useMemo(() =>{
     if(socialPosting && socialPosting.post_feed) {
        if(socialPosting.post_feed.request_status) {
          setMessage("post Add Successfully!");
          setError("");
        }
     }
     else if(socialPosting && socialPosting.post_feed_error) {
        if (socialPosting.post_feed_error.object.error) {
          setError(socialPosting.post_feed_error.object.error);
          setMessage("");
        }
     }
     if(socialPosting && socialPosting.delete_feed) {
        if(socialPosting.delete_feed.request_status) {
          setMessage("post Deleted Successfully!");
          setError("");
        }
     }
     else if(socialPosting && socialPosting.delete_feed_error) {
        if (socialPosting.delete_feed_error.object.error) {
          setError(socialPosting.delete_feed_error.object.error);
          setMessage("");
        }
     }

  },[socialPosting])

  const onPageChange = (currentPage) => {
    setCurrentPage(currentPage);
    dispatch(getFeedPosts(accountId, currentPage))
  };

  const postFeedCall = (e) => {
    inputValues.images = images;
    if(selectedDate.length > 0) {
      inputValues.scheduleDate = selectedDate + " " + scheduleTime;
    }
    if(socialPosting.post_feed_loading === false) {
       setCurrentPage(0);
       dispatch(postFeed(accountId, inputValues))
    }
  }

  const deleteFeedCall = (id) => {
    if(window.confirm("Are you want to delete post?")){
      dispatch(deleteFeed(accountId, id))
     }
  }


  const handleDateChange = (value) => {
    const selectedDate = moment(value).format('YYYY-MM-DD');
    setScheduleDate(value);
    setSelectedDate(selectedDate);
  }

  // resetHandler function start
  const handleTimeChange = (value) => {
    const selectedTime = value && value.format('HH:mm') ? value.format('HH:mm') : "00:00";
    setScheduleTime(selectedTime);
   }

  // resetHandler function start
  const resetHandler = (event) =>{
    event.preventDefault()
    window.location.reload(false);
  }

  const refineContentCall = (value) =>{
    mess = "";
    if(loadedMessage === true) {
    setLoadedMessage(false);
    setInputValues({...inputValues, message:"Refining....."});    
     // Making a POST request first
    fetch(`${config.api_root}/api/v1/merchants/social_account/refine_content`, {
      method: 'POST', // Although SSE typically uses GET, this is an example with POST
      headers: { 'Accept': 'application/json',
          'Authorization': 'Bearer '+ config.token,
          'Content-Type': 'application/json' },
      body: JSON.stringify({ user_id: config.user_id,
        fields: inputValues.message })
    }).then(response => response.json())
       .then(res =>{
          if(res.request_status){
             setInputValues({...inputValues, message:"Refining....."});
             setLoadedMessage(false);
             const eventSource = new EventSource(`${config.api_root}/api/v1/merchants/social_account/refine_content/${res.object.thread_id}?access_token=${config.token}`);

             eventSource.onopen = () => {
               setInputValues({...inputValues, message:"Refining....."});
               setLoadedMessage(false);
             };

             eventSource.addEventListener("thread.run.created", function (event) {
             }, false);

             eventSource.addEventListener("thread.run.queued", function (event) {}, false);
             
             eventSource.addEventListener("thread.run.in_progress", function (event) {
     
             }, false);
             
             eventSource.addEventListener("thread.run.failed", function (event) {
      
             }, false);
             
             eventSource.addEventListener("thread.message.delta", function (event) {
               let json = JSON.parse(event.data);
               for (var i = 0 ; i < json.delta.content.length; i++) {
                  mess += json.delta.content[i].text.value;
                  setInputValues({...inputValues, message: mess});
               }
               setLoadedMessage(true);
             }, false);
             
             eventSource.addEventListener("thread.run.complete", function (event) {
      
             }, false);

             eventSource.onmessage = (event) => {
                 const newMessage = JSON.parse(event.data);
             };

             eventSource.onerror = (error) => {
                 console.error("Event Source Error:", error);
                 eventSource.close();
             };

             return () => {
                eventSource.close();
             };
          } else {
            
          }
       })
      .catch(error => {
        const errorMsg = error
      })
    }
  }  

  const renderPagination = () => {
    let items = [];
    for (let i = 0; i < totalPages; i++) {
      if (i == currentPage || i == 0 || i == (parseInt(currentPage)-1) || i == (parseInt(currentPage)+1) || i == (totalPages-1)) items.push(
    <Pagination.Item
      key={i}
      active={i == currentPage}
      onClick={() => onPageChange(i)}
      >
      {(i == totalPages-1)?`...${(i+1)}`: i == 0 ?`${(i+1)}...`:(i+1)}
    </Pagination.Item>
    );
    }
    return (
     <Pagination style={{ overflowX: "auto" }}>
     <Pagination.Prev
      disabled={currentPage === 0}
      onClick={() => onPageChange(currentPage - 1)}
     />
    {items}
     <Pagination.Next
      disabled={currentPage === totalPages-1}
      onClick={() => onPageChange(currentPage + 1)}
     />
     </Pagination>
    );
  };

// add data of allexams api into constant,hook start
   useMemo(() =>{
     if(socialPosting.get_feed && socialPosting.get_feed.data && socialPosting.get_feed.request_status === true){
       setTotalPages(Math.ceil(socialPosting.get_feed.total / pageSize));
       setFeedPosts(socialPosting.get_feed.data)
     }
   },[socialPosting])
// add data of allexams api into constant,hook End

        return (<>
            <div className="breadcrumbs-area">
               <h3>Social Posting</h3>
               <ul>
                 <li>
                   <Link to="/">Home</Link>
                 </li>
                 <li>Post Feed List ({accountName})</li>
               </ul>
            </div>

            <div className="row dashboard-card-six">
            
            <div className="col-8-xxxl col-12">
            <div className="card">
                <div className="card-body">
                      
                  { message ?
                    <p className="alert alert-success w-auto" role="alert">
                      {message}
                    </p> : <p></p>
                  }

                  { error ?
                    <p className="alert alert-danger w-auto" role="alert">
                      {error}
                    </p> : <p></p>
                  }

                  <div className="table-box-wrap">

                      <div className="row gutters-8">
                        <div className="col-lg-4 form-group">

                        </div>
                      </div>

                      {socialPosting && (socialPosting.get_feed_loading === true || socialPosting.delete_feed_loading === true) ?
                        (<div className="card-body">
                        <div className="Student-Loader">
                        <Loader
                        className = "student-detail-loader"
                      type="ThreeDots"
                      color="#fea801"
                      height={150}
                      width={150}

         />
                        </div>
                      </div>) : (<div className="table-responsive expenses-table-box">

                    <table className="table data-table text-nowrap">
                        <thead>
                          <tr>
                            <th>S.No.</th>
                            <th>Image</th>
                            <th>Message</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                        {feedPosts && feedPosts.map((item,index)=>{
                          return (
                            <tr>
                               <td>{currentPage*10 + (index+1)}</td>
                               <td>{item.images.length > 0 ? (<img src={item.images[0].post_url} width="60px" height="40px"/>) : "No Image" }</td>  
                               <td>{item.message}</td>
                               <td>
                                   <button type="button" className="btn-fill-lg bg-blue-dark btn-hover-yellow" onClick={(e) =>deleteFeedCall(item.id)}>Delete</button>
                               </td>                            
                            </tr>
                          )
                        })}


                        </tbody>
                      </table>
                    </div>)}
                  </div>
                </div>
                <div className='row'>
                   <div className="col-12-xxxl col-12">     
                     <div style={{ marginTop: '10px' }}>{renderPagination()}</div>  
                   </div>
                </div>
              </div>
              
              
              
              </div>

            <div className="col-4-xxxl col-12">

            <div className="card">
            <div className="card-body">
            {socialPosting && (socialPosting.post_feed_loading === true || galleryData.is_loading === true) ?
                        (<div className="card-body">
                        <div className="Student-Loader">
                        <Loader
                        className = "student-detail-loader"
                      type="ThreeDots"
                      color="#fea801"
                      height={50}
                      width={150}

         />
                        </div>
                      </div>) : (<div></div>)}
                 <div>
                    <div className="heading-layout1 bg-darkblue">
                      <div className="item-title">
                         <h3 className='text-light'>Add Post</h3>
                       </div>
                    </div>
            
                      <div className="row">
                        <div className="col-12-xxxl col-lg-6 col-12 form-group">
                          <label>Message *</label>
                          {/* <textarea placeholder="Message" className="form-control" value={inputValues.message} onChange={(e) =>setInputValues({...inputValues,message:e.target.value})} required>
                      
                        </textarea> */}
                        <textarea
                            className="form-control"
                            required
                            value={inputValues.message}
                            onChange={(e) =>setInputValues({...inputValues, message:e.target.value})}
                            placeholder="Enter your text here"
                            style={{height:80}}
                            rows={3}
                          />
                          {/* <input type="text" placeholder className="form-control" value={inputValues.message} onChange={(e) =>setInputValues({...inputValues, mṇessage:e.target.value})} required/> */}
                          {error != null && error.message ? (<h6 className="addStudent-error">*{JSON.stringify(error.message).replace(/[\[\]"]+/g,"")}</h6>):null}
                        </div>

                          <div className="col-12 form-group mg-t-8" style={{paddingTop:"10px"}}>
                             <button type="button" style={{marginLeft:"10px"}} className={loadedMessage === true ? 'btn-fill-lg bg-blue-dark btn-hover-yellow right-float' : 'btn-fill-lg btn-hover-grey right-float'} onClick={(e) =>refineContentCall(e)}>Refine Message</button>
                          </div>
                        
                        </div>

                        <div className="row">
                        <div className="col-6-xxxl col-lg-6 col-12 form-group">
                          <label>Schedule Date</label>
                          <DatePicker selected={scheduleDate} className="form-control" onChange={(date) => handleDateChange(date)} />
                           {/* {error != null && error.scheduleDate ? (<h6 className="addStudent-error">*{JSON.stringify(error.scheduleDate).replace(/[\[\]"]+/g,"")}</h6>):null} */}
                           
                                                
                        </div>
                        <div className="col-6-xxxl col-lg-6 col-12 form-group">
                          <label>Schedule Time</label>
                          <TimePicker
                                showSecond={false}
                                defaultValue={now}
                                className="form-control timeselector"
                                onChange={handleTimeChange}
                                format={format}
                                use12Hours
                                inputReadOnly
                                />
                          {/* {error != null && error.scheduleTime ? (<h6 className="addStudent-error">*{JSON.stringify(error.scheduleTime).replace(/[\[\]"]+/g,"")}</h6>):null} */}
                                                
                        </div>
            </div>
                      <div className="row" >

                        <div className="right-float mg-t-20 mg-l-10" style={{justifyContent: 'center'}}>
                  <button className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark mg-b-10 mg-r-10" onClick={addImage}>+</button>
                  
                  <button className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark" onClick={removeImage}>-</button>
               </div>

                  </div>
                  <div className="row cntr reporterror mg-t-10" style={{justifyContent: 'center'}}>{errorMessage}</div>
                 <div className="row cntr" style={{justifyContent: 'center'}}>

                        {images.map((image, index) => (
                        <div className="row col-lg-8 col-12 form-group mg-0 mt-2">
                          <div className="rcorners1" id={`socialImageId_${index}`} style={{background:  "#afb0ad"}} type="image">
                            <button type="button" onClick={(e) => uploadFunction(index)} style={{marginTop: '70px'}} className='btn-fill-lg bg-blue-dark btn-hover-yellow'>Upload</button>
                            <input type="hidden" name="images" value={image}/>
                            
                          </div>
                        </div>
                         ))}

                        <div className="col-12 form-group mg-t-8" style={{paddingTop:"20px"}}>
                          <button type="button" style={{marginLeft:"10px"}} className={socialPosting.post_feed_loading === false ? 'btn-fill-lg bg-blue-dark btn-hover-yellow right-float' : 'btn-fill-lg btn-hover-grey right-float'} onClick={(e) =>postFeedCall(e)}>Post</button>
                          <button type="reset" className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark right-float" onClick={(e) =>resetHandler(e)}>Reset</button>
                        </div>
                      </div>
                      </div>
                    </div>
                    
                    </div>
            </div>
                  
              </div>
            </>
        );
}

export default AccountFeedPosts;
