import {
  GET_GALLERY_REQUEST,
  GET_GALLERY_SUCCESS,
  GET_GALLERY_FALIURE,
  UPLOAD_REQUEST,
  UPLOAD_SUCCESS,
  UPLOAD_FALIURE
} from './GalleryConstants'

const intialstate = {
  is_loading : false,
  success_status : false,
  get_galleries : null,
  upload_image: null,
  get_error : ''
}

const GalleryReducers = (state = intialstate,action) =>{
  switch(action.type){
    case GET_GALLERY_REQUEST : return{
      ...state,
      is_loading: true,
      success_status: false,
      get_galleries: null,
      get_error: null
    }
    case GET_GALLERY_SUCCESS : return{
      ...state,
      is_loading: false,
      success_status: true,
      get_galleries: action.payload
    }
    case GET_GALLERY_FALIURE : return{
      ...state,
      is_loading: false,
      success_status: false,
      get_error:action.payload
    }
    case UPLOAD_REQUEST : return{
      ...state,
      is_loading: true,
      success_status: false,
      upload_image: null,
      get_error: null
    }
    case UPLOAD_SUCCESS : return{
      ...state,
      is_loading: false,
      success_status: true,
      upload_image: action.payload
    }
    case UPLOAD_FALIURE : return{
      ...state,
      is_loading: false,
      success_status: false,
      get_error:action.payload
    }
    default: return state
  }

}

export default GalleryReducers
