import React, {useState, useEffect, useMemo} from 'react';
import { Link } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux'
import { addEventStearms } from '../Redux/EventStearms/EventStearmsActions'
import { config } from '../Redux/config';

function RefineContent() {
  
  const dispatch = useDispatch();
  const [messages, setMessages] = useState('');
  const [inputMessage, setInputMessage] = useState('');
  const [isStreaming, setIsStreaming] = useState(false);
  const [error, setError] = useState('');
  
  useEffect(() => {
  
  }, []);

  const refineContentCall = (value) =>{
    setMessages("");    
     // Making a POST request first
    fetch(`${config.api_root}/api/v1/merchants/social_account/refine_content`, {
      method: 'POST', // Although SSE typically uses GET, this is an example with POST
      headers: { 'Accept': 'application/json',
          'Authorization': 'Bearer '+ config.token,
          'Content-Type': 'application/json' },
      body: JSON.stringify({ user_id: config.user_id,
        fields: inputMessage })
    }).then(response => response.json())
       .then(res =>{
          if(res.request_status){
             //console.log(res.object.thread_id)
             setMessages("Writing.....");
             const eventSource = new EventSource(`${config.api_root}/api/v1/merchants/social_account/refine_content/${res.object.thread_id}?access_token=${config.token}`);

             eventSource.onopen = () => {
               setMessages("");
               //console.log('Connected to SSE stream');
             };

             eventSource.addEventListener("thread.run.created", function (event) {
             }, false);

             eventSource.addEventListener("thread.run.queued", function (event) {}, false);
             
             eventSource.addEventListener("thread.run.in_progress", function (event) {
     
             }, false);
             
             eventSource.addEventListener("thread.run.failed", function (event) {
      
             }, false);
             
             eventSource.addEventListener("thread.message.delta", function (event) {
               let json = JSON.parse(event.data);
               for (var i = 0 ; i < json.delta.content.length; i++) {
                  // RJSON += json.delta.content[i].text.value;
                  setMessages((prev) => [...prev, json.delta.content[i].text.value]);
               }
             }, false);
             
             eventSource.addEventListener("thread.run.complete", function (event) {
      
             }, false);

             eventSource.onmessage = (event) => {
                 const newMessage = JSON.parse(event.data);
             };

             eventSource.onerror = (error) => {
                 console.error("Event Source Error:", error);
                 eventSource.close();
             };

             return () => {
                eventSource.close();
             };
          } else {
            
          }
       })
      .catch(error => {
        const errorMsg = error
      })
  }

        return (           
          <>
          <div className="breadcrumbs-area">
            <h3>Refine Contents</h3>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Refine Contents</li>
            </ul>
          </div>
            {/* Breadcubs Area Start Here */}
            <div className="row">
            <div className="col-8-xxxl col-12">
               <div className="card">
                 <div className="card-body">
                    <div className="heading-layout1 mg-b-17">
                      <div className="item-title">
                        <h3>Stream Output:</h3>
                      </div>
                    </div>

                    <div>
                        <p>{messages ? messages : 'Waiting for messages...'}</p>
                    </div>
                 </div>
               </div>
             </div>

             <div className="col-4-xxxl col-12">
               <div className="card">
                 <div className="card-body">
                  
                    <div className="col-12-xxxl col-lg-6 col-12 form-group">
                          <label>Submit Text *</label>
                          <textarea
                            className="form-control"
                            required
                            value={inputMessage}
                            onChange={(e) =>setInputMessage(e.target.value)}
                            placeholder="Enter your text here"
                            style={{height:80}}
                            rows={3}
                          />
                          {/* <input type="text" placeholder className="form-control" value={inputMessage} onChange={(e) =>setInputMessage(e.target.value)} required/> */}
                          {error != null && error.inputMessage ? (<h6 className="addStudent-error">*{JSON.stringify(error.inputMessage).replace(/[\[\]"]+/g,"")}</h6>):null}
                    </div>

                    <div className="col-12 form-group mg-t-8" style={{paddingTop:"20px"}}>
                          <button type="button" style={{marginLeft:"10px"}} className="btn-fill-lg bg-blue-dark btn-hover-yellow right-float" onClick={(e) => refineContentCall(e)}>Submit</button>
                    </div>

                 </div>
               </div>
             </div>

            </div>
          </>       
        );
}

export default RefineContent;
