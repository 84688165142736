import {
  CONNECT_ACCOUNT_REQUEST,
  CONNECT_ACCOUNT_SUCCESS,
  CONNECT_ACCOUNT_FALIURE,
  PROCESS_ACCOUNT_REQUEST,
  PROCESS_ACCOUNT_SUCCESS,
  PROCESS_ACCOUNT_FALIURE,
  GET_ACCOUNTS_REQUEST,
  GET_ACCOUNTS_SUCCESS,
  GET_ACCOUNTS_FALIURE,
  POST_FEED_REQUEST,
  POST_FEED_SUCCESS,
  POST_FEED_FALIURE,
  DELETE_FEED_REQUEST,
  DELETE_FEED_SUCCESS,
  DELETE_FEED_FALIURE,
  GET_FEED_REQUEST,
  GET_FEED_SUCCESS,
  GET_FEED_FALIURE,
  AVAILABLE_ACCOUNTS_REQUEST,
  AVAILABLE_ACCOUNTS_SUCCESS,
  AVAILABLE_ACCOUNTS_FALIURE,
  RESET_REQUEST,
  SAVE_ACCOUNT_REQUEST,
  SAVE_ACCOUNT_SUCCESS,
  SAVE_ACCOUNT_FALIURE
} from './SocialPostingConstants'
import {config} from '../config'

export const connectAccountRequest = (connect_account) =>{
  return{
    type : CONNECT_ACCOUNT_REQUEST,
    payload : connect_account
  }
}

export const connectAccountSuccess = (connect_account_success) =>{
  return{
    type : CONNECT_ACCOUNT_SUCCESS,
    payload : connect_account_success
  }
}

export const connectAccountFaliure = (error) =>{
  return{
    type : CONNECT_ACCOUNT_FALIURE,
    payload : error
  }
}

export const processAccountsRequest = (process_account) =>{
  return{
    type : PROCESS_ACCOUNT_REQUEST,
    payload : process_account
  }
}

export const processAccountsSuccess = (process_account_success) =>{
  return{
    type : PROCESS_ACCOUNT_SUCCESS,
    payload : process_account_success
  }
}

export const processAccountsFaliure = (error) =>{
  return{
    type : PROCESS_ACCOUNT_FALIURE,
    payload : error
  }
}


export const getAccountsRequest = (get_account) =>{
  return{
    type : GET_ACCOUNTS_REQUEST,
    payload : get_account
  }
}

export const getAccountsSuccess = (get_account_success) =>{
  return{
    type : GET_ACCOUNTS_SUCCESS,
    payload : get_account_success
  }
}

export const getAccountsFaliure = (error) =>{
  return{
    type : GET_ACCOUNTS_FALIURE,
    payload : error
  }
}

export const postFeedRequest = (post_feed) =>{
  return{
    type : POST_FEED_REQUEST,
    payload : post_feed
  }
}

export const postFeedSuccess = (post_feed_success) =>{
  return{
    type : POST_FEED_SUCCESS,
    payload : post_feed_success
  }
}

export const postFeedFaliure = (error) =>{
  return{
    type : POST_FEED_FALIURE,
    payload : error
  }
}


export const saveAccountRequest = (save_account) =>{
  return{
    type : SAVE_ACCOUNT_REQUEST,
    payload : save_account
  }
}

export const saveAccountSuccess = (save_account_success) =>{
  return{
    type : SAVE_ACCOUNT_SUCCESS,
    payload : save_account_success
  }
}

export const saveAccountFaliure = (error) =>{
  return{
    type : SAVE_ACCOUNT_FALIURE,
    payload : error
  }
}

export const deleteFeedRequest = (delete_feed) =>{
  return{
    type : DELETE_FEED_REQUEST,
    payload : delete_feed
  }
}

export const deleteFeedSuccess = (delete_feed_success) =>{
  return{
    type : DELETE_FEED_SUCCESS,
    payload : delete_feed_success
  }
}

export const deleteFeedFaliure = (error) =>{
  return{
    type : DELETE_FEED_FALIURE,
    payload : error
  }
}

export const getFeedRequest = (get_feed) =>{
  return{
    type : GET_FEED_REQUEST,
    payload : get_feed
  }
}

export const getFeedSuccess = (get_feed_success) =>{
  return{
    type : GET_FEED_SUCCESS,
    payload : get_feed_success
  }
}

export const getFeedFaliure = (error) =>{
  return{
    type : GET_FEED_FALIURE,
    payload : error
  }
}

export const getAvailableAccountRequest = (available_account) =>{
  return{
    type : AVAILABLE_ACCOUNTS_REQUEST,
    payload : available_account
  }
}

export const getAvailableAccountSuccess = (available_account_success) =>{
  return{
    type : AVAILABLE_ACCOUNTS_SUCCESS,
    payload : available_account_success
  }
}

export const getAvailableAccountFaliure = (error) =>{
  return{
    type : AVAILABLE_ACCOUNTS_FALIURE,
    payload : error
  }
}

export const resetRequest = (data) => {
  return {
    type : RESET_REQUEST,
    payload : data
  }
}

export const resetAll = () =>{
  return(dispatch) => {
    dispatch(resetRequest(""));
  }
}

export const connectAccount = (connect_account) =>{
    return(dispatch) => {
      dispatch(connectAccountRequest(connect_account))
      const url =`${config.api_root}/api/v1/merchants/oauth/init/facebook`;
      const request_option = {
      method: "PATCH",
      headers: {
              'Accept': 'application/json',
              'Authorization': 'Bearer '+ config.token,
              'Content-Type': 'application/json'
      }
     }
     fetch(url, request_option)
     .then(response => response.json())
     .then(connect_account_res =>{
        const connect_account_success = connect_account_res
        dispatch(connectAccountSuccess(connect_account_success))
     })
     .catch(error => {
       const errorMsg = error
       dispatch(connectAccountFaliure(errorMsg))
     })
  }
}

export const processAccount = (accountId, authCode) =>{
  return(dispatch) => {
    dispatch(processAccountsRequest(accountId))
    const url =`${config.api_root}/api/v1/merchants/oauth/process/access_key/facebook?accountId=${accountId}&authCode=${authCode}`;
    const request_option = {
    method: "PATCH",
    headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer '+ config.token,
            'Content-Type': 'application/json'
    }
   }
   fetch(url, request_option)
   .then(response => response.json())
   .then(connect_account_res =>{
      const connect_account_success = connect_account_res
      if(connect_account_success.request_status) dispatch(processAccountsSuccess(connect_account_success))
      else dispatch(processAccountsFaliure(connect_account_success))
   })
   .catch(error => {
     const errorMsg = error
     dispatch(processAccountsFaliure(errorMsg))
   })
}
}

export const getAccounts = (connect_account, currentPage) =>{
  return(dispatch) => {
    dispatch(getAccountsRequest(connect_account))
    const url =`${config.api_root}/api/v1/merchants/social_accounts?page=${currentPage}&size=10`;
    const request_option = {
    method: "GET",
    headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer '+ config.token,
            'Content-Type': 'application/json'
    }
   }
   fetch(url, request_option)
   .then(response => response.json())
   .then(connect_account_res =>{
      const connect_account_success = connect_account_res
      dispatch(getAccountsSuccess(connect_account_success))
   })
   .catch(error => {
     const errorMsg = error
     dispatch(getAccountsFaliure(errorMsg))
   })
}
}


export const postFeed = (accountId, data) =>{
  return(dispatch) => {
     dispatch(postFeedRequest(accountId))
     const url =`${config.api_root}/api/v1/merchants/social_account/${accountId}`;
     const request_option = {
       method: "POST",
       headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer '+ config.token,
            'Content-Type': 'application/json'
       },
       body: JSON.stringify({
         message : data.message,
         images : data.images,
         scheduleDate: data.scheduleDate
       })
     }
    fetch(url, request_option)
    .then(response => response.json())
    .then(connect_account_res =>{
        const connect_account_success = connect_account_res
        if(connect_account_success.request_status) {
          dispatch(getFeedPosts(accountId, 0));
          dispatch(postFeedSuccess(connect_account_success))
        }
        else dispatch(postFeedFaliure(connect_account_success))
    })
    .catch(error => {
      const errorMsg = error
      dispatch(postFeedFaliure(errorMsg))
    })
 }
}


export const saveAccounts = (accountId, data) =>{
  return(dispatch) => {
     dispatch(saveAccountRequest(""))
     const url =`${config.api_root}/api/v1/merchants/accounts/facebook?account_id=${accountId}`;
     const request_option = {
       method: "POST",
       headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer '+ config.token,
            'Content-Type': 'application/json'
       },
       body: JSON.stringify(data)
     }
    fetch(url, request_option)
    .then(response => response.json())
    .then(connect_account_res =>{
        const connect_account_success = connect_account_res
        if(connect_account_success.request_status) {
          dispatch(getAccounts(accountId, 0))
          dispatch(saveAccountSuccess(connect_account_success))
        }
        else dispatch(saveAccountFaliure(connect_account_success))
    })
    .catch(error => {
      const errorMsg = error
      dispatch(saveAccountFaliure(errorMsg))
    })
 }
}

export const deleteFeed = (accountId, feedId) =>{
  return(dispatch) => {
     dispatch(deleteFeedRequest(accountId))
     const url =`${config.api_root}/api/v1/merchants/social_account/${accountId}/${feedId}`;
     const request_option = {
     method: "DELETE",
     headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer '+ config.token,
            'Content-Type': 'application/json'
     }
     }
     fetch(url, request_option)
     .then(response => response.json())
     .then(connect_account_res =>{
       const connect_account_success = connect_account_res
       if(connect_account_success.request_status) {
         dispatch(deleteFeedSuccess(connect_account_success))
         dispatch(getFeedPosts(accountId, 0));
       }
       else dispatch(deleteFeedFaliure(connect_account_success))
     })
     .catch(error => {
      const errorMsg = error
      dispatch(deleteFeedFaliure(errorMsg))
     })
 }
}


export const getFeedPosts = (accountId, currentPage) =>{
  return(dispatch) => {
    dispatch(getFeedRequest(accountId))
      const url =`${config.api_root}/api/v1/merchants/social_account/${accountId}/posts?page=${currentPage}&size=10`;
      const request_option = {
      method: "GET",
      headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer '+ config.token,
            'Content-Type': 'application/json'
      }
    }
    fetch(url, request_option)
    .then(response => response.json())
    .then(connect_account_res =>{
      const connect_account_success = connect_account_res
      dispatch(getFeedSuccess(connect_account_success))
    })
    .catch(error => {
     const errorMsg = error
     dispatch(getFeedFaliure(errorMsg))
    })
 }
}

export const getAvailableAccounts = (accountId, currentPage) =>{
  return(dispatch) => {
      dispatch(getAvailableAccountRequest(accountId))
      //const url =`${config.api_root}/api/v1/merchants/available_accounts/facebook?page=${currentPage}&size=10`;
      const url =`${config.api_root}/api/v1/merchants/available_accounts/facebook?account_id=${accountId}&page=${currentPage}&size=10`;
      const request_option = {
      method: "GET",
      headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer '+ config.token,
            'Content-Type': 'application/json'
      }
    }
    fetch(url, request_option)
    .then(response => response.json())
    .then(connect_account_res =>{
      const connect_account_success = connect_account_res
      if(connect_account_success.request_status) dispatch(getAvailableAccountSuccess(connect_account_success))
      else dispatch(getAvailableAccountFaliure(connect_account_success))
    })
    .catch(error => {
     const errorMsg = error
     dispatch(getAvailableAccountFaliure(errorMsg))
    })
 }
}