import React, { useMemo, useState, useEffect } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import store from './Redux/store';
import { loginCheck } from './Redux/LoginCheck/LoginCheckActions';
import { fetchSpecificUserDetail, fetchSpecificUserDetailfromCookies } from './Redux/SpecificUserDetail/SpecificUserDetailActions';
// import { Redirect } from "react-router-dom";
import { Provider } from 'react-redux';

import Footer from './Components/Footer';
import Header from './Components/Header';
import Sidebar from './Components/Sidebar';

//Login portion
import Login from './Components/Login';

//admin portion
import App from './App';

import './App.css';
import RestTiming from './Components/RestTiming';
import Categories from './Components/Categories';
import AllCategories from './Components/AllCategories';
import Products from './Components/Products';
import AllOrders from './Components/AllOrders';
import AllEvents from './Components/AllEvents';
import AllContact from './Components/AllContact';
import AllHolidays from './Components/AllHolidays';
import AddonGroups from './Components/AddonGroups';
import PrinterSettings from './Components/PrinterSettings';
import AllReports from './Components/AllReports';
import AllMarketing from './Components/AllMarketing';
import AddonGroupsAddons from './Components/AddonGroupsAddons';
import PrinterSetting from './Components/PrinterSetting';
import OrderDetail from './Components/OrderDetail';
import EditCategory from './Components/EditCategory';
import EditProduct from './Components/EditProduct';
import EditAddonGroup from './Components/EditAddonGroup';
import EGiftCardRedeem from './Components/EGiftCardRedeem';
import DeliverySettings from './Components/DeliverySettings';
import OrderDetailPrint from './Components/OrderDetailPrint';
import ETicketRedeem from './Components/ETicketRedeem';
import BannerLogoUpload from './Components/BannerLogoUpload';
import Galleries from './Components/Galleries';
import Cookies from 'js-cookie';
import EditProfile from './Components/EditProfile';
import BroadCast from './Components/BroadCast';
import SingleCategoryList from './Components/SingleCategoryList';
import Product from './Components/Product';
import AddProduct from './Components/AddProduct';
import AddCategory from './Components/AddCategory';
import SocialPosting from './Components/SocialPosting';
import EditProductCategory from './Components/EditProductCategory';
import DirectEditProduct from './Components/DirectEditProduct';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import ViewSalesAnalytics from './Components/ViewSalesAnalytics';
import Events from './Components/Events';
import Coupons from './Components/Coupons';
import AllCoupons from './Components/AllCoupons';
import SubDomain from './Components/SubDomain';
import GiftCertificates from './Components/GiftCertificates';
import SpecialDeals from './Components/SpecialDeals';
import Outlet from './Components/Outlet';
import ProcessSocialPosting from './Components/ProcessSocialPosting';
import AccountFeedPosts from './Components/AccountFeedPosts';
import RefineContent from './Components/RefineContent';



function Root() {
  const [isLogin, setIsLogin] = useState(false)
  const [currentUser, setCurrentUser] = useState(null)
 

  // if (window.performance) {
  //   if (performance.navigation.type == 1) {
  //     alert( "This page is reloaded" );
  //   } else {
  //     alert( "This page is not reloaded");
  //   }
  // }

  const [activestate, setActivestate] = useState('')
  const callbackFunction = (childData) => {
    setActivestate(childData)
  }

  store.subscribe(() => {
    if (store.getState() &&
      store.getState().LoginCheck &&
      store.getState().LoginCheck.is_Login
    ) {
      setIsLogin(store.getState().LoginCheck.is_Login)
    }
  });

  store.subscribe(() => {
    if (store.getState() &&
      store.getState().LoginCheck &&
      store.getState().LoginCheck.is_Login &&
      store.getState().SpecificUserDetail &&
      store.getState().SpecificUserDetail.specific_user_detail
    ) {
      setCurrentUser(store.getState().SpecificUserDetail)
      // console.log('currentuser', store.getState().SpecificUserDetail.specific_user_detail);

    }
  });

  useEffect(() => {
    if (localStorage.getItem("access_token") != undefined && localStorage.getItem("user_email") != undefined) {
      const login_check_info = true
      store.dispatch(loginCheck(login_check_info))
      store.dispatch(fetchSpecificUserDetail())
    }
    else if (Cookies.get("RS_ACCESS_TOKEN") != undefined && Cookies.get("USER") != undefined) {
      const login_check_info = true
      const cookies_token = Cookies.get("RS_ACCESS_TOKEN");
      const cookies_user = Cookies.get("USER")
      localStorage.setItem("access_token", cookies_token);
      localStorage.setItem("user_email", cookies_user);
      store.dispatch(loginCheck(login_check_info))
      store.dispatch(fetchSpecificUserDetail())
      //console.log('cookies found', Cookies.get("RS_ACCESS_TOKEN"));
    }
  }, [])
  return (
    <Provider store={store}>
      <Router>


              <Switch>
              //login route
                <Route path="/login" exact render={() => (
                  localStorage.getItem("access_token") != undefined && localStorage.getItem("user_email") != undefined ? (alert("You can't login if you are logged in!"), (<Redirect to="/" />)) : (<Login />)
                )} />
                {localStorage.getItem("access_token") != undefined || Cookies.get("RS_ACCESS_TOKEN") != undefined ? (
                  <div id="wrapper" className={activestate ? 'wrapper bg-ash sidebar-collapsed' : 'wrapper bg-ash'}>

                  <Header parentCallback={() => callbackFunction()} />

                  <div className="dashboard-page-one">
                    <Sidebar />
                    <div className="dashboard-content-one">
                  <>
                    <Route exact path="/" component={App} />
                    
                    <Route exact path="/viewsalesanalytics" component={ViewSalesAnalytics} />
                    <Route exact path="/rest-timing" component={RestTiming} />
                    <Route exact path="/categories" component={AllCategories} />
                    <Route exact path="/products" component={Products} />
                    <Route exact path="/orders" component={AllOrders} />
                    {/* the below event name is converted to specails */}
                    <Route exact path="/events" component={AllEvents} />
                    <Route exact path="/eventslist" component={Events} />
                    {/* here specials */}
                    <Route exact path="/coupons" component={AllCoupons} />
                    <Route exact path="/couponslist" component={Coupons} />
                    <Route exact path="/contact-us" component={AllContact} />
                    <Route exact path="/holidays" component={AllHolidays} />
                    <Route exact path="/addongroups" component={AddonGroups} />
                    <Route exact path="/restaurant-settings" component={PrinterSettings} />
                    <Route exact path="/download-reports" component={AllReports} />
                    <Route exact path="/marketing-plan" component={AllMarketing} />
                    <Route exact path="/manage-addons" component={AddonGroupsAddons} />
                    <Route exact path="/printer-settings" component={PrinterSetting} />
                    <Route exact path="/order_detail" component={OrderDetail} />
                    <Route exact path="/order_detail_print" component={OrderDetailPrint} />
                    <Route exact path="/edit_category" component={EditCategory} />
                    <Route exact path="/edit_profile" component={EditProfile} />
                    <Route exact path="/edit_product" component={EditProduct} />
                    <Route exact path="/social_posting" component={SocialPosting} />
                    <Route exact path="/social_posts" component={AccountFeedPosts} />
                    <Route exact path="/fb_business_account_authorization" component={ProcessSocialPosting} />
                    <Route exact path="/edit_addons" component={EditAddonGroup} />
                    <Route exact path="/banner_logo_upload" component={BannerLogoUpload} />
                    <Route exact path="/egift_card_redeem" component={EGiftCardRedeem} />
                    <Route exact path="/eticket_redeem/:id" component={ETicketRedeem} />
                    <Route exact path="/delivery-settings" component={DeliverySettings} />
                    <Route exact path="/refine_content" component={RefineContent} />
                    <Route exact path="/galleries" component={Galleries} />
                    <Route exact path="/broadcast" component={BroadCast} />
                    <Route exact path="/singlecategory" component={SingleCategoryList} />
                    <Route exact path="/product" component={Product} />
                    <Route exact path="/addproduct" component={AddProduct} />
                    <Route exact path="/addcategory" component={AddCategory} />
                    <Route exact path="/editproductcategory" component={EditProductCategory} />
                    <Route exact path="/direct_edit_product" component={DirectEditProduct} />
                    <Route exact path="/order_online_link" component={SubDomain} />
                    <Route exact path="/gift-certificates" component={GiftCertificates} />
                    <Route exact path="/special_deals" component={SpecialDeals} />
                    <Route exact path="/outlet_setting" component={Outlet} />
                  </>
                  <Footer />
            </div>
          </div>
        </div>
                ) : (<>
                  <Route path={"/"}
                    render={() => (window.location = "/login")} />
                </>)}
              </Switch>

      </Router>
    </Provider>
  );
}

export default Root;
