

import React, {useState,useEffect,useMemo} from 'react';
import Loader from 'react-loader-spinner';
import DateTimePicker from 'react-datetime-picker';
import {config} from '../Redux/config'
import * as FormData from 'form-data'
import DatePicker from 'react-datepicker';
import Switch from "react-switch";

import "react-datepicker/dist/react-datepicker.css";
import 'rc-time-picker/assets/index.css';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import {
    Form,
  FormGroup
} from "react-bootstrap";
import { useSelector,useDispatch } from 'react-redux'
import moment from 'moment';
import { fetchAllNotices, addEvent } from '../Redux/AllNotices/AllNoticeActions';
import { addcoupon, fetchAddCoupon, fetchAllCouponsList } from '../Redux/AllCoupon/AllCouponsActions';
function AddCoupon(){
const fs = require('fs');
  // store data access start
const classes = useSelector(state =>state.AllClasses)
const coupons = useSelector(state =>state.AllCoupon)
console.log(coupons);
const sections = useSelector(state =>state.AllSections)
const subjects = useSelector(state =>state.AllSubjects)
const teachers = useSelector(state =>state.AllTeachers)
const addExam_data = useSelector(state =>state.AddTiming)

const [startDate, setStartDate] = useState(new Date());
const [endDate, setEndDate] = useState(new Date());
// store data access End
  const dispatch = useDispatch()  // for accessing the redux function

  // component all states define start
  const [couponsResult,setCouponsResult] = useState([])
  const [allClassesInfo,setClassesInfo] = useState([])
  const [allSectionsInfo,setSectionsInfo] = useState([])
  const [allSubjectsInfo,setSubjectsInfo] = useState([])
  const [teacherResult,setTeacherResult] = useState([])
  const [allTeachersInfo,setTeachersInfo] = useState([])
  const [inputValues,setInputValues] = useState({
                                          validFrom: moment().format('DD-MM-YYYY'),
                                          validTo : moment().format('DD-MM-YYYY'),
                                          code:"",
                                          description:"",
                                        minSpend:"",
                                        maxSpend:"",
                                        minBenefit:"",
                                        maxBenefit:"",
                                        amount:"",
                                        fixed: false,
                                        autoApply: false,
                                        couponDiscountType:"",
                                        couponType:"",
                                     

  })
  const [mediumId,setMediumId] = useState('')
    const [error,setError] = useState(null)
    const [successStatus,setSuccessStatus] = useState(null)


const format = 'HH:mm';

const now = moment().hour(0).minute(0);
const [data, setData] = useState([]);
const [data1, setData1] = useState([]);
  // component all states define End

   //hooks start
   // fetch classes and teachers api hook start
   useEffect(() => {

    const url =`${config.api_root}/api/v1/merchants/coupon/${config.mid}/build/setting`;
    const request_option = {
      method: "GET",
      headers: {
              'Accept': 'application/json',
              'Authorization': 'Bearer '+ config.token,
              'Content-Type': 'application/json'
          },
      }
    fetch(url,request_option, {
      // Pass the date filter and the current order tab as query parameters
})
   
    // Assume the JSON data is stored in a file called data.json
    .then((response) => response.json()) // Convert the response to JSON
    .then((data) => {
    // Set the data to the state variable
    // console.log(data.object.coupon_types);
    // setData(data);
    console.log(data.object.coupon_discount_types);

    var couponDiscount = data.object.coupon_discount_types;
    var keys = Object.values(couponDiscount);
    


    var couponTypes = data.object.coupon_types;
// Set the data to the state variable
setData(couponTypes);
setData1(keys);

    })
    .catch((error) => console.error(error)); // Handle any errors
    }, []);

  
   
// add data of addExam api into constant,hook End

   //hooks end

// component function start
// classHandler function start

// classHandler function End

// examHandler function start
   const resetCoupon = () => {
     var coupon = {
       validFrom: moment().format('DD-MM-YYYY'),
       validTo : moment().format('DD-MM-YYYY'),
       code:"",
       description:"",
       minSpend:"",
       maxSpend:"",
       minBenefit:"",
       maxBenefit:"",
       fixed: false,
       autoApply: false,
       amount:"",
       couponDiscountType:"",
       couponType:"",
     }
     setInputValues(coupon);
   }
   const couponHandler = (event) =>{
   event.preventDefault()
   const formData = new FormData();
    // formData.append('code', inputValues.code);
    // formData.append('description', inputValues.description);
    // formData.append('validFrom', inputValues.validFrom);
    // formData.append('validTo', inputValues.validTo);
    // formData.append('minSpend', inputValues.minSpend);
    // formData.append('maxSpend', inputValues.maxSpend);
    // formData.append('amount', inputValues.amount);
    // formData.append('couponDiscountType', inputValues.couponDiscountType);
    // formData.append('couponType', inputValues.couponType);
    // formData.append('file', inputValues.file);
    // formData.append('user_id', config.user_id);

   const exam_info = inputValues;
     dispatch(addcoupon(exam_info))
    //  dispatch(fetchAllCouponsList())
 }
// examHandler function End

// resetHandler function start
   const resetHandler = (event) =>{
     event.preventDefault()
     window.location.reload(false);
   }
// resetHandler function start


const getBase64Path = (e) => {
  const file = e.target.files[0]
  let reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = () => {
    setInputValues({...inputValues,file})
  };
  reader.onerror = function (error) {
    console.log('Error: ', error);
  }
}

// resetHandler function start
   const handleDateChange = (value) =>{
     const selectedData = moment(value).format('DD-MM-YYYY');

    setInputValues({...inputValues,validFrom:selectedData})
    setStartDate(value)
   }


   const handleClosingTimeChange = (value) =>{

     const selectedData = moment(value).format('DD-MM-YYYY');
     setInputValues({...inputValues,validTo:selectedData})
     setEndDate(value);
    //console.log('selectedTime', selectedTime);
   }

   const handleAutoApply = (value) =>{
     if(inputValues.autoApply === true)
       setInputValues({...inputValues, autoApply:false})
     else setInputValues({...inputValues, autoApply:true})
   }
// resetHandler function start

// component function end

// useMemo(() =>{
//     if(coupons && coupons.all_coupons.object){
//         setCouponsResult(coupons.all_coupons.object)
//     }
//   },[coupons])


  useMemo(() =>{
    if(coupons && coupons.all_coupons && coupons.all_coupons.object && coupons.all_coupons.object.length > 0){
      setCouponsResult(coupons.all_coupons.object)
    }
  },[coupons])

  useMemo(() =>{
    if(coupons.add_coupon && coupons.add_coupon.request_status && coupons.add_coupon.request_status === true){
      resetCoupon()
    }
  },[coupons])

  const [show,setShow] = useState(true);
  // var options = data.map((item, index) => {
  //   // Return an option element with the index as value and the item as text
  //   return <option value={inputValues.couponType} onChange={(e) =>setInputValues({...inputValues,couponType:e.target.value})}>{item}</option>;
  //   });


        return (
            <div className="col-4-xxxl col-4 d-lg-block d-md-none">
                <div className="card height-auto">
                  <div className="card-body">
                    <div className="heading-layout1 bg-darkblue">
                      <div className="item-title">
                        <h3 className='text-light'>Add Coupons</h3>
                        
                      </div>

                    </div>

                    {coupons.add_coupon_loading ?
                          <div className='mg-l-40'>
                          <Loader
                            className = "cl-center"
                            type="MutatingDots"
                            color="#fea801"
                            height={150}
                            width={150}    
                          /></div>
                         : coupons.add_coupon && coupons.add_coupon.request_status && coupons.add_coupon.request_status === true && show ? (<div className="success-greeting1">
                             <h2 className="addtimesuccess cl-center">Coupons Added Successfully!</h2>                              
                          </div>) : coupons.add_coupon_error && coupons.add_coupon_error.object && coupons.add_coupon_error.object.error ? (<div className="success-greeting1">
                             <h2 className="addtimeerror cl-center">{coupons.add_coupon_error.object.error}</h2>
                          
                           </div>): null}

                           <form className="new-added-form" onSubmit={(e) =>couponHandler(e)} encType="multipart/form-data">
                      <div className="row">

                      <div className="col-6-xxxl col-lg-6 col-12 form-group">
                      <label>Code</label>
                          <input placeholder className="form-control" value={inputValues.code} onChange={(e) =>setInputValues({...inputValues,code:e.target.value})} required/>
                          {/* {error != null && error.code ? (<h6 className="addStudent-error">*{JSON.stringify(error.code).replace(/[\[\]"]+/g,"")}</h6>):null} */}
                        </div>
                        <div className="col-6-xxxl col-lg-6 col-12 form-group">
                          <label>Amount</label>
                          <input type="number" placeholder className="form-control" value={inputValues.amount} onChange={(e) =>setInputValues({...inputValues,amount:Number(e.target.value)})} required/>
                        </div>
                       
                        <div className="col-12-xxxl col-lg-12 col-12 form-group">
                          <label>Description</label>
                          <input type="text" placeholder className="form-control" value={inputValues.description} onChange={(e) =>setInputValues({...inputValues,description:e.target.value})} required/>
                          {error != null && error.description ? (<h6 className="addStudent-error">*{JSON.stringify(error.description).replace(/[\[\]"]+/g,"")}</h6>):null}
                        </div>

                        <div className="col-12-xxxl col-lg-12 col-12 form-group">
                      <label>Coupon Discount Type</label>
                      <Form.Select aria-label="Default select example" className='form-control' value={inputValues.couponDiscountType} onChange={(e) =>setInputValues({...inputValues,couponDiscountType:e.target.value})}>
<option>Select Coupon Discount Type</option>
{data1.map((item, index) => {
   
   return <option value={index+1}>{item}</option>;
   })}
    </Form.Select>
                          </div>
                          <div className="col-12-xxxl col-lg-12 col-12 form-group">
<label>Coupon Type</label>
<Form.Select aria-label="Default select example" className='form-control' value={inputValues.couponType} onChange={(e) =>setInputValues({...inputValues,couponType:e.target.value})}>
<option>Select Coupon Type</option>
{data.map((item, index) => {
   
    return <option value={item}>{item}</option>;
    })}
</Form.Select>
</div>


                        <div className="col-6-xxxl col-lg-6 col-12 form-group">
                          <label>Valid From</label>
                          <DatePicker
                              onChange={(date) => handleDateChange(date)}
                              selected={startDate}
                              className="form-control"
                            />
                        </div>
                        <div className="col-6-xxxl col-lg-6 col-12 form-group">
                          <label>Valid To</label>
                        <DatePicker
                              onChange={(date) => handleClosingTimeChange(date)}
                              selected={endDate}
                              className="form-control"
                            />
                        </div>

                        <div className="col-12-xxxl col-lg-12 col-12 form-group">
                          <label className="lables" style={{marginTop:20}}>Auto Apply</label>
                          <Switch
                                    onChange={(e) =>
                                      handleAutoApply()
                                    }
                                    checked={inputValues.autoApply}
                                    uncheckedIcon={false}
                                    checkedIcon={true}
                                    
                                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                  />
                          
                        </div>

                        <div className="col-6-xxxl col-lg-6 col-12 form-group">
                          <label>Minimum Spend</label>
                          <input type="number" placeholder className="form-control" value={inputValues.minSpend} onChange={(e) =>setInputValues({...inputValues,minSpend:Number(e.target.value)})} required/>
                        </div>
                        <div className="col-6-xxxl col-lg-6 col-12 form-group">
                          <label>Maximum Spend</label>
                          <input type="number" placeholder className="form-control" value={inputValues.maxSpend} onChange={(e) =>setInputValues({...inputValues,maxSpend:Number(e.target.value)})} required/>
                        </div>

                        <div className="col-6-xxxl col-lg-6 col-12 form-group">
                          <label>Minimum Benefit</label>
                          <input type="number" placeholder className="form-control" value={inputValues.minBenefit} onChange={(e) =>setInputValues({...inputValues,minBenefit:Number(e.target.value)})} required/>
                        </div>
                        <div className="col-6-xxxl col-lg-6 col-12 form-group">
                          <label>Maximum Benefit</label>
                          <input type="number" placeholder className="form-control" value={inputValues.maxBenefit} onChange={(e) =>setInputValues({...inputValues,maxBenefit:Number(e.target.value)})} required/>
                        </div>

                        {/* <FormGroup controlId="formFile" className="col-12-xxxl col-lg-6 col-12 form-group">
                        <label>Image</label>
                        <input type="file" placeholder className="form-control" onChange={(e) =>getBase64Path(e)}/>

                      </FormGroup> */}

                        <div className="col-12 form-group mg-t-8" style={{paddingTop:"20px"}}>
                        <button type="submit" style={{marginLeft:"10px"}} className="btn-fill-lg bg-blue-dark btn-hover-yellow right-float">Save</button> 
                        <button type="reset" className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark right-float" onClick={(e) =>resetHandler(e)}>Reset</button>
                        
                        </div>
                      </div>
                    </form>
                  </div>
                
                </div>
              </div>
        );
}

export default AddCoupon;

