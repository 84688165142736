import React, {useState,useEffect,useMemo} from 'react';
import 'rc-time-picker/assets/index.css';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { useSelector, useDispatch } from 'react-redux'
import { connectAccount, processAccount, getAccounts} from '../Redux/SocialPosting/SocialPostingActions'

function AddSocialPosting(){
  // store data access start
const socialPostings = useSelector(state =>state.SocialPosting)
// store data access End
  const dispatch = useDispatch()  // for accessing the redux function

  // component all states define start
  // const [connectAccount, setConnectAccount] = useState([])
  
  // component all states define End

   //hooks start
   // fetch classes and teachers api hook start
   useEffect(() =>{
    // dispatch(fetchAllClasses())
    // dispatch(fetchAllTeachers())
   },[dispatch])
// fetch classes and teachers api hook End

// add data of classes api into constant,hook start
   useMemo(() =>{
     if(socialPostings && socialPostings.connect_account && socialPostings.connect_account.object && socialPostings.connect_account_loading === false){
        window.location.href = socialPostings.connect_account.object;
        //setConnectAccount(socialPostings.connect_account);
     }
   },[socialPostings])
// add data of classes api into constant,hook End

const connectAccountCall = () =>{
  dispatch(connectAccount());
}

// resetHandler function start
   const resetHandler = (event) =>{
     event.preventDefault()
     window.location.reload(false);
   }
// resetHandler function start


    return (
        <div className="col-4-xxxl col-12">
            <div className="card height-auto">
              <button type="button" className="btn-fill-lg bg-blue-dark btn-hover-yellow" onClick={(e) =>connectAccountCall()}>Facebook Connect Account</button>
            </div>
        </div>
    );
}

export default AddSocialPosting;
