import React from 'react';
import { Link } from 'react-router-dom';
import AddSocialPosting from './AddSocialPosting';
function SocialPosting() {
  
        return (
            <>
            <div className="breadcrumbs-area">
              <h3>Social Posting</h3>
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>Get Available Accounts</li>
              </ul>
            </div>
            {/* Breadcubs Area End Here */}
            {/* Exam Schedule Area Start Here */}
            <div className="row">
              <AddSocialPosting />

            </div>
            </>
        );
}

export default SocialPosting;
