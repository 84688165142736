import {
  CONNECT_ACCOUNT_REQUEST,
  CONNECT_ACCOUNT_SUCCESS,
  CONNECT_ACCOUNT_FALIURE,
  PROCESS_ACCOUNT_REQUEST,
  PROCESS_ACCOUNT_SUCCESS,
  PROCESS_ACCOUNT_FALIURE,
  GET_ACCOUNTS_REQUEST,
  GET_ACCOUNTS_SUCCESS,
  GET_ACCOUNTS_FALIURE,
  POST_FEED_REQUEST,
  POST_FEED_SUCCESS,
  POST_FEED_FALIURE,
  DELETE_FEED_REQUEST,
  DELETE_FEED_SUCCESS,
  DELETE_FEED_FALIURE,
  GET_FEED_REQUEST,
  GET_FEED_SUCCESS,
  GET_FEED_FALIURE,
  AVAILABLE_ACCOUNTS_REQUEST,
  AVAILABLE_ACCOUNTS_SUCCESS,
  AVAILABLE_ACCOUNTS_FALIURE,
  RESET_REQUEST,
  RESET_ACCOUNTS,
  SAVE_ACCOUNT_REQUEST,
  SAVE_ACCOUNT_SUCCESS,
  SAVE_ACCOUNT_FALIURE
} from './SocialPostingConstants'

const intialstate = {
  connect_account_loading : false,
  connect_account : null,
  connect_account_error : '',
  connect_account_success : false,
  process_account_loading : false,
  process_account : null,
  process_account_error : '',
  process_account_success : false,
  
  get_accounts_loading : false,
  get_accounts : [],
  get_accounts_error : '',
  get_accounts_success : false,

  post_feed_loading : false,
  post_feed : null,
  post_feed_error : '',
  post_feed_success : false,
  delete_feed_loading : false,
  delete_feed : null,
  delete_feed_error : '',
  delete_feed_success : false,

  get_feed_loading : false,
  get_feed : [],
  get_feed_error : '',
  get_feed_success : false,

  available_accounts_loading : false,
  available_accounts : [],
  available_accounts_error : '',
  available_accounts_success : false,

  save_account_loading : false,
  save_account : null,
  save_account_error : '',
  save_account_success : false
}

const SocialPostingReducers = (state = intialstate,action) =>{
  switch(action.type){
    case RESET_REQUEST : return{
      ...state,
      connect_account_loading:true,
      connect_account: null,
      available_accounts: null,
      process_account: null,
      process_account_success: false,
      connect_account_loading: false,
      get_accounts: null,
      get_feed: null,
      post_feed: null,
      delete_feed: null,
      get_feed_error: null,
      post_feed_error: null,
      connect_account_success: null,
      delete_feed_error: null,
      connect_account_error: null
    }
    case CONNECT_ACCOUNT_REQUEST : return{
      ...state,
      connect_account_loading:true,
      connect_account: null,
      connect_account_error: null
    }
    case CONNECT_ACCOUNT_SUCCESS : return{
      ...state,
      connect_account_loading:false,
      connect_account_success:true,
      connect_account:action.payload
    }
    case CONNECT_ACCOUNT_FALIURE : return{
      ...state,
      connect_account_error:action.payload
    }

    case PROCESS_ACCOUNT_REQUEST : return{
      ...state,
      process_account_loading:true,
      process_account: null,
      process_account_error: null
    }
    case PROCESS_ACCOUNT_SUCCESS : return{
      ...state,
      process_account_loading:false,
      process_account_success:true,
      process_account:action.payload
    }
    case PROCESS_ACCOUNT_FALIURE : return{
      ...state,
      process_account_error:action.payload
    }

    case GET_ACCOUNTS_REQUEST : return{
      ...state,
      get_accounts_loading:true,
      get_accounts: null,
      get_accounts_error: null
    }
    case GET_ACCOUNTS_SUCCESS : return{
      ...state,
      get_accounts_loading:false,
      get_accounts_success:true,
      get_accounts:action.payload
    }
    case GET_ACCOUNTS_FALIURE : return{
      ...state,
      get_accounts_error:action.payload
    }

    case POST_FEED_REQUEST : return{
      ...state,
      post_feed_loading: true,
      post_feed: null,
      post_feed_error: null
    }
    case POST_FEED_SUCCESS : return{
      ...state,
      post_feed_loading:false,
      post_feed_success:true,
      post_feed:action.payload
    }
    case POST_FEED_FALIURE : return{
      ...state,
      post_feed_loading:false,
      post_feed_error:action.payload
    }

    case SAVE_ACCOUNT_REQUEST : return{
      ...state,
      save_account_loading: true,
      save_account: null,
      save_account_error: null
    }
    case SAVE_ACCOUNT_SUCCESS : return{
      ...state,
      save_account_loading:false,
      save_account_success:true,
      save_account:action.payload
    }
    case SAVE_ACCOUNT_FALIURE : return{
      ...state,
      save_account_loading:false,
      save_account_success:false,
      save_account_error:action.payload
    }

    case DELETE_FEED_REQUEST : return{
      ...state,
      delete_feed_loading: true,
      delete_feed: null,
      delete_feed_error: null
    }
    case DELETE_FEED_SUCCESS : return{
      ...state,
      delete_feed_loading:false,
      delete_feed_success:true,
      delete_feed:action.payload
    }
    case DELETE_FEED_FALIURE : return{
      ...state,
      delete_feed_loading:false,
      delete_feed_error:action.payload
    }

    case GET_FEED_REQUEST : return{
      ...state,
      get_feed_loading: true,
      get_feed: null,
      get_feed_error: null
    }
    case GET_FEED_SUCCESS : return{
      ...state,
      get_feed_loading:false,
      get_feed_success:true,
      get_feed:action.payload
    }
    case GET_FEED_FALIURE : return{
      ...state,
      get_feed_error:action.payload
    }

    case AVAILABLE_ACCOUNTS_REQUEST : return{
      ...state,
      available_accounts_loading: true,
      available_accounts: null,
      available_accounts_error: null
    }
    case AVAILABLE_ACCOUNTS_SUCCESS : return{
      ...state,
      available_accounts_loading: false,
      available_accounts_success:true,
      available_accounts: action.payload
    }
    case AVAILABLE_ACCOUNTS_FALIURE : return{
      ...state,
      available_accounts_loading: false,
      available_accounts: null,
      available_accounts_error: action.payload
    }
    default: return state
  }

}

export default SocialPostingReducers
