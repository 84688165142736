import React, {useState,useEffect,useRef,useMemo} from 'react';
import Switch from "react-switch";
import { fetchRestSettings, updateRestSettings } from '../Redux/RestSettings/RestSettingsActions'

import { useSelector,useDispatch } from 'react-redux';
import { fetchAllBook, acceptOrder, declineOrder } from '../Redux/AllBook/AllBookActions';
import { restShut, restShutUpdate, restShut2Update } from '../Redux/RestShut/RestShutActions';
import { wakeUpHours } from '../Redux/WakeUpSettings/WakeUpSettingsActions';
import { deliveryShut, deliveryShutUpdate } from '../Redux/DeliveryShut/DeliveryShutActions';
import { registerDevice } from '../Redux/Login/LoginActions';

import moment from 'moment';
import Loader from 'react-loader-spinner';
import { useHistory } from 'react-router-dom';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { FormControl } from 'react-bootstrap';


function UpdateRestSettings(){

const [restOnOff,setRestOnOff] = useState(false)

const [deliveryOnOff,setDeliveryOnOff] = useState(false)
const allBookData = useSelector(state =>state.AllBook)
const restStateData = useSelector(state =>state.RestShutStatus)
const deliveryStateData = useSelector(state =>state.DeliveryShutStatus)
const loginData = useSelector(state =>state.Login)
const wakeUpData = useSelector(state =>state.WakeUpSettings)

// store data access End
const dispatch = useDispatch()  // for accessing the redux function
const history = useHistory();
const [allBookResult,setBookResult] = useState([])

const [startDate, setStartDate] = useState();
const [endDate, setEndDate] = useState();
const [error,setError] = useState(null)
const [hour,setHour] = useState(0)
const [st,setSt] = useState(0)
const [pageNumber, setPageNumber] = useState(0)
const [data,setData] = useState([])
const [currentorderTab,setCurrentorderTab] = useState(2)
const [responsMessage, setResponsMessage] = useState('')
const [errorMessage, setErrorMessage] = useState('')
const [curTime, setCurTime] = useState('')
const listInnerRef = useRef();


// form
const addExam_data = useSelector(state =>state.AddTiming)
const allRestSettings = useSelector(state =>state.RestSettings)

const [successStatus,setSuccessStatus] = useState(null)

// fetch allBook  api hook start
useEffect((url2) =>{
  
  setSt(0);
  dispatch(fetchAllBook(0, false))
  dispatch(restShut())
  dispatch(fetchRestSettings())  
  dispatch(deliveryShut())

  if(localStorage.getItem('device_token') && localStorage.getItem('new_token') && localStorage.getItem('new_token') == 'true') {
     dispatch(registerDevice(localStorage.getItem('device_token')))
  }
  componentDidMount();
},[dispatch])


// fetch allBook api hook End

const componentDidMount = () => {
  setInterval(() => {
    const time = new Date().toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    });
    setCurTime(time)  
    setSt(1);
  }, 1000)
}




// add data of allFees api into constant,hook start
useMemo(() =>{
  if(allBookData && allBookData.all_book && allBookData.all_book && !allBookData.all_book_loading){
    setError('');
    if(allBookData.is_added){
      allBookData.all_book.forEach((item) => {
        if(allBookResult.indexOf(item) == -1){
          setBookResult(allBookResult.concat(allBookData.all_book));
        }
      });
    } else if(!allBookData.is_added) {
      setBookResult(allBookData.all_book)
      setData(allBookData.all_book)
    }
  }
},[allBookData])

// add data of addExam api into constant,hook start
useMemo(()=>{
  if(addExam_data && addExam_data.add_timing && addExam_data.add_timing.error){
    setError(addExam_data.add_timing.error)
  }
  else if(addExam_data && addExam_data.add_timing && addExam_data.add_timing.success){
     setSuccessStatus(addExam_data.add_timing.success)
  }
},[addExam_data])





const [restSettingsData,setRestSettingsData] = useState({
  robo_call: true,
  sms_phone_number: "",
  merchant_phone_number: "",
  merchant_emails: [],
  pickup_time: "",
  delivery_time: ""

})

useMemo(()=>{
  if(allRestSettings && allRestSettings.rest_settings && allRestSettings.rest_settings.object){
    setRestSettingsData(allRestSettings.rest_settings.object)
    
  }

},[allRestSettings])

const onScroll = () => {
  if (listInnerRef.current) {
    const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
 
    if (Math.ceil(scrollTop + clientHeight) == scrollHeight) {
     
      if(startDate && endDate){
        dispatch(fetchAllBook(pageNumber+1, true, {
           dateFilter: {
             startDate: moment(startDate).format('DD-MM-YYYY'),
             endDate: moment(endDate).format('DD-MM-YYYY'),
           },
           filter: currentorderTab
        }))
      } else {
        dispatch(fetchAllBook(pageNumber+1, true, {
          filter: currentorderTab
        }))
      }
      setPageNumber(pageNumber+1);
      // This will be triggered after hitting the last element.
      // API call should be made here while implementing pagination.
    }
  }
};

// add data of allFees api into constant,hook End

useMemo(() => {
  if(loginData && loginData.device) {
    if(loginData.device.object && loginData.device.object.pushEndpoint) {
      localStorage.setItem('new_token', 'false');
    }
  }
}, [loginData])

// add data of allFees api into constant,hook start
useMemo(() =>{
  if(restStateData && restStateData.rest_shut && restStateData.rest_shut.success){ 
    setRestOnOff(restStateData.rest_shut.status)
    
  }
},[restStateData])

useMemo(() =>{
  if(restStateData && restStateData.rest_post_shut && restStateData.rest_post_shut.success){
    setRestOnOff(restStateData.rest_post_shut.status)
    setResponsMessage('');
  }
},[restStateData])

useMemo(() =>{
  if(wakeUpData && wakeUpData.wake_up) {
    if(wakeUpData.wake_up.success=="true") {
      setRestOnOff(false)
      dispatch(restShut())
      setErrorMessage('')
      setResponsMessage('Restaurant is closed now for '+hour+' hour(s)! Open At: '+wakeUpData.wake_up.times)
    } else if(wakeUpData.wake_up.object && wakeUpData.wake_up.object.error) {
      setResponsMessage('')
      setErrorMessage(wakeUpData.wake_up.object.error)
    }
  }
},[wakeUpData])

const [show,setShow] = useState(true);
const examHandler = (event) =>{
  event.preventDefault()
    dispatch(updateRestSettings(restSettingsData))
    setInterval(() => {
      setShow(false); 
  }, 2000); 
   
}
// add data of allFees api into constant,hook End

// add data of allFees api into constant,hook start
useMemo(() =>{
  if(deliveryStateData && deliveryStateData.delivery_shut && deliveryStateData.delivery_shut.status){
    setDeliveryOnOff(deliveryStateData.delivery_shut.status)
  }
},[deliveryStateData])
// add data of allFees api into constant,hook End

const handlefilterSelect = (e) => {
    setPageNumber(0);
    if(e === 'all') {
      setCurrentorderTab(0);
    }
    else {
      setCurrentorderTab(e);
    }
    if(startDate && endDate) {
      if(startDate > endDate || startDate > new Date() || endDate > new Date()) setError('invalid Date')
      else if((endDate - startDate) / (1000 * 3600 * 24) > 31) setError('Orders can not be more than 31 days!')
      else dispatch(fetchAllBook(0, false, {
        dateFilter: {
          startDate: moment(startDate).format('DD-MM-YYYY'),
          endDate: moment(endDate).format('DD-MM-YYYY'),
        },
        filter: e
      }))
    } else {
       dispatch(fetchAllBook(0, false, {filter:e}))
    }
}




// resetHandler function start
const resetHandler = (event) =>{
  event.preventDefault()
  window.location.reload(false);
}



const setrestStatus = (event) =>{
   setRestOnOff(!restOnOff)
  
    dispatch(restShutUpdate(!restOnOff))
  
    
}



const handleWakeUpHours = () =>{
   const hour = document.getElementById('close_hour').value;
   setHour(hour);
   setSt(0);
   if(hour > 0) {
     dispatch(wakeUpHours(hour))
   }
   dispatch(restShut2Update(restStateData.rest_shut.status))
}



return (
    <>
    
  
      {/* Dashboard summery start Here  */}
    
        <div className="col-xl-5 col-sm-7 col-12">
          <div className="dashboard-summery-one mg-b-20">
            <div className="row align-items-center">
              <div className="col-6">
                <div className="item-icon bg-light-green ">
                  <i className="flaticon-calendar text-green" />
                  
                </div>
                {restOnOff == true ? <span style={{color:'green'}}>Open Now</span> : <span style={{color:'red'}}>Closed {wakeUpData.wake_up.times ? <>Until {wakeUpData.wake_up.times}</> : <>Now</>}</span>} 
                <br/>
                {!restOnOff ? 
                 <div style={{marginLeft: '10px'}}>
                  
                   <Switch  onChange={e=>setrestStatus(e)} checked={restOnOff} uncheckedIcon={false}
                     checkedIcon={false}
                     boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                     offColor='#f83124'
                     activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"/>
                 </div> : <span></span>}

                
              </div>
            



           <div className="col-6">
               <div className="item-content">
                  <div className="item-number">Pause Restaurant</div>
                  <div className="">
                  <select id="close_hour" className="form-select mg-t-3" aria-label=".form-select-lg example">
                    <option selected value="0">Select Hours</option>
                    <option value="1">1 Hour</option>
                    <option value="2">2 Hours</option>
                    <option value="3">3 Hours</option>
                    <option value="4">4 Hours</option>
                    <option value="5">5 Hours</option>
                    <option value="6">6 Hours</option>
                    <option value="7">7 Hours</option>
                    <option value="8">8 Hours</option>
                    <option value="9">9 Hours</option>
                    <option value="10">10 Hours</option>
                    <option value="11">11 Hours</option>
                    <option value="12">12 Hours</option>
                    <option value="13">13 Hours</option>
                    <option value="14">14 Hours</option>
                    <option value="15">15 Hours</option>
                    <option value="16">16 Hours</option>
                    <option value="17">17 Hours</option>
                    <option value="18">18 Hours</option>
                    <option value="19">19 Hours</option>
                    <option value="20">20 Hours</option>
                    <option value="21">21 Hours</option>
                    <option value="22">22 Hours</option>
                    <option value="23">23 Hours</option>
                  </select>
                  <button type="button" style={{float:'right'}} onClick={(e) => handleWakeUpHours()} className='btn-fill-sm gradient-pastel-green btn-hover-bluedark mg-r-10 mg-l-5'>Pause</button>

                 </div>
               </div>

              
               
           </div>

           <div className="content-center">
            {!wakeUpData.wake_up_loaded ?
               (<div style={{textAlign: 'center'}}>
                  <Loader
                    className="student-detail-loader"
                    type="ThreeDots"
                    color="#fea801"
                    height={90}
                    width={150}
                  />
                </div>) : (<span></span>) }
             {responsMessage ?
              <p className="alert alert-success w-auto" role="alert">
               {responsMessage}
              </p> : <p></p>
             }

             {errorMessage ?
              <p className="alert alert-danger w-auto content-center" role="alert">
               {errorMessage}
              </p> : <p></p>
             }

          </div>

            </div>
          </div>
        </div>


<div className="card height-auto">
                {addExam_data && addExam_data.add_timing_loading === false ? successStatus === false || successStatus === null  ? (
                  <div className="card-body">
                    <div className="heading-layout1">
                      <div className="item-title">

                        {allRestSettings && allRestSettings.rest_update_loading && allRestSettings.rest_update_success === false ? (<div className="success-greeting1">
                        <h2 className="addtimesuccess">Restaurant Info Updating...</h2>
                        </div>): null}

                        {allRestSettings && allRestSettings.rest_update_success && allRestSettings.rest_update_loading === false && show? allRestSettings.rest_settings.object.error ? (<div className="success-greeting1">
                        <h2 className="addtimesuccess error">{allRestSettings.rest_settings.object.error}</h2>
                        </div>) : (<div className="success-greeting1">
                        <h2 className="addtimesuccess">Restaurant Updated Successfully!</h2>
                        </div>): null}
                      </div>

                    </div>
                    <form className="new-added-form" onSubmit={(e) =>examHandler(e)}>
                      <div className="row">
                        <div className="col-lg-6 form-group">
                          <label>SMS Phone Number *</label>
                  
                          <input type="text" placeholder className="form-control" value={restSettingsData.sms_phone_number} onChange={(e) =>setRestSettingsData({...restSettingsData,sms_phone_number:e.target.value})} />
                          {error != null && error.name ? (<h6 className="addStudent-error">*{JSON.stringify(error.name).replace(/[\[\]"]+/g,"")}</h6>):null}
                        </div>
                        <div className="col-lg-6 form-group">
                          <label>Merchant Phone Number</label>
                          <input type="text" placeholder className="form-control" value={restSettingsData.merchant_phone_number} onChange={(e) =>setRestSettingsData({...restSettingsData,merchant_phone_number:e.target.value})} />
                          {error != null && error.description ? (<h6 className="addStudent-error">*{JSON.stringify(error.description).replace(/[\[\]"]+/g,"")}</h6>):null}
                        </div>

                        <div className="col-lg-6 form-group">
                          <label>Merchant Emails</label>
                          <input type="text" placeholder className="form-control" value={restSettingsData.merchant_emails} onChange={(e) =>setRestSettingsData({...restSettingsData,merchant_emails:e.target.value.split(',')})} />
                          {error != null && error.description ? (<h6 className="addStudent-error">*{JSON.stringify(error.description).replace(/[\[\]"]+/g,"")}</h6>):null}
                        </div>

                        <div className="col-lg-6 form-group">
                          <label>Pickup Time</label>
                          <input type="text" placeholder className="form-control" value={restSettingsData.pickup_time} onChange={(e) =>setRestSettingsData({...restSettingsData,pickup_time:e.target.value})} />
                          {error != null && error.description ? (<h6 className="addStudent-error">*{JSON.stringify(error.description).replace(/[\[\]"]+/g,"")}</h6>):null}
                        </div>

                        <div className="col-lg-6 form-group">
                          <label>Delivery Time</label>
                          <input type="text" placeholder className="form-control" value={restSettingsData.delivery_time} onChange={(e) =>setRestSettingsData({...restSettingsData,delivery_time:e.target.value})} />
                          {error != null && error.description ? (<h6 className="addStudent-error">*{JSON.stringify(error.description).replace(/[\[\]"]+/g,"")}</h6>):null}
                        </div>

                        <div className="col-lg-6 form-group">
                          <label>Robo Call</label>
                        <FormControl
                          required
                          type="text"
                         as="select"
                          value={restSettingsData.robo_call}
                          onChange={(e) =>setRestSettingsData({...restSettingsData,robo_call:e.target.value})}
                        >
                        <option value="true">Enable</option>
                        <option value="false">Disable</option>
                        </FormControl>
                        </div>


                        <div className="col-12 form-group mg-t-8">
                          <button type="submit"  style={{marginLeft:"10px"}} className="btn-fill-lg bg-blue-dark btn-hover-yellow right-float">Save</button>
                          <button type="reset" className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark right-float" onClick={(e) =>resetHandler(e)}>Reset</button>
                        </div>
                      </div>
                    </form>
                  </div>
                ) : (<div className="card-body">
                      <div className="success-greeting">
                      <h2>Thank You!</h2>
                      <h2>Settings Updated Successfully!</h2>
                      </div>
                  </div>):(<div className="card-body">
                      <div className="Student-Loader">
                      <Loader
                      className = "student-detail-loader"
                    type="MutatingDots"
                    color="#fea801"
                    height={150}
                    width={150}

       />
                      </div>
                    </div>)}
                </div>
       

     

    

    
      {/* Dashboard Content End Here */}
    
      </>

        );
}

export default UpdateRestSettings;
