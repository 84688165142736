import {
  ADD_EVENT_STEARMS_REQUEST,
  ADD_EVENT_STEARMS_SUCCESS,
  ADD_EVENT_STEARMS_FALIURE
} from './EventStearmsConstants'

const intialstate = {
  is_loading : false,
  success_status : false,
  add_event_stearms : null,
  get_error : ''
}

const EventStearmsReducers = (state = intialstate,action) =>{
  switch(action.type){
    case ADD_EVENT_STEARMS_REQUEST : return{
      ...state,
      is_loading: true,
      success_status: false,
      add_event_stearms: null
    }
    case ADD_EVENT_STEARMS_SUCCESS : return{
      ...state,
      is_loading: false,
      success_status: true,
      add_event_stearms: action.payload
    }
    case ADD_EVENT_STEARMS_FALIURE : return{
      ...state,
      is_loading: false,
      success_status: false,
      add_event_stearms: null,
      get_error:action.payload
    }
    default: return state
  }

}

export default EventStearmsReducers
