import React, {useState,useEffect,useMemo} from 'react';

import Loader from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import { useSelector, useDispatch } from 'react-redux'
import { connectAccount, processAccount, getAccounts, postFeed, resetAll, deleteFeed, getAvailableAccounts, saveAccounts } from '../Redux/SocialPosting/SocialPostingActions'

import { Button, Pagination } from 'react-bootstrap';
import { useLocation, Link, useHistory } from 'react-router-dom/cjs/react-router-dom';

function AvailableAccounts() {
  // store data access start
  const socialPosting = useSelector(state =>state.SocialPosting);

  const location = useLocation();
  // Extract the query parameters from the current URL
  const queryParams = new URLSearchParams(location.search);
 
// State to manage whether the checkbox is checked
  const [checkedItems, setCheckedItems] = useState([]);

  const handleCheckboxChange = (index) => (event) => {
    const checked = event.target.checked;

    setCheckedItems((prevCheckedItems) => {
      if (checked) {
        // Add the index if it's checked
        return [...prevCheckedItems, index];
      } else {
        // Remove the index if it's unchecked
        return prevCheckedItems.filter(item => item !== index);
      }
    });
    
  };

  

  const history = useHistory();
  // store data access End
  const dispatch = useDispatch()  // for accessing the redux function

  // component all states define start
  const [availableAccounts, setAvailableAccounts] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [processAccounts, setProcessAccounts] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [message, setMessage] = useState();
  const [error, setError] = useState();
  const [accountType, setAccountType] = useState();
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [isCalled, setIsCalled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // fetch allexams api hook start
  useEffect(() => {
     dispatch(resetAll());
     setAccountType("ALL");
     setMessage(null);
     setError(null);
     dispatch(getAccounts(totalPages, currentPage))
     if(null != queryParams.get('code')){
        const code = queryParams.get('code');
        dispatch(processAccount("", code));
        setIsCalled(true);
        // const timer = setTimeout(() => {
        //   setIsCalled(true);
        // }, 2000);
        
        //dispatch(getAvailableAccounts(totalPages,0))
     }

     const handleLoad = () => {
      setIsLoading(false);  // Set loading state to false once the page has fully loaded
    };

    window.addEventListener('load', handleLoad);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener('load', handleLoad);
    };

  }, [dispatch])
  // fetch allexams api hook End

  useMemo(() =>{
    // console.log(socialPosting.process_account.object)
     /*if(socialPosting && socialPosting.post_feed) {
        if (socialPosting.post_feed.request_status) setMessage("post Add Successfully!");
     }
     else if(socialPosting && socialPosting.post_feed_error) {
        if (socialPosting.post_feed_error.object.error) setError(socialPosting.post_feed_error.object.error);
     }*/
     if(socialPosting && socialPosting.delete_feed) {
        if (socialPosting.delete_feed.request_status) setMessage("post Deleted Successfully!");
     }
     else if(socialPosting && socialPosting.delete_feed_error) {
        if (socialPosting.delete_feed_error.object.error) setError(socialPosting.delete_feed_error.object.error);
     }

     if(socialPosting && socialPosting.save_account && socialPosting.save_account_success) {       
        if(socialPosting.save_account.request_status) {
          setMessage("Save Account Successfully!");
          if(null != queryParams.get('code')) {
             setError("");
             setMessage("");
             setAccountType("ALL");
             history.push(`/fb_business_account_authorization`);
          }
        }
     } else if(socialPosting && socialPosting.save_account_error) {
        if(!socialPosting.save_account_error.request_status) {
          setMessage("");
          setError(socialPosting.save_account_error.object.error);
        }
     } else if(socialPosting && socialPosting.available_accounts && socialPosting.available_accounts.object) {
        setMessage("");
        setAccounts(socialPosting.available_accounts.object.data);
        //setTotalPages(Math.ceil(socialPosting.get_accounts.object.total / pageSize));
        if(null != queryParams.get('code')) {
          setAccountType("ACCOUNTS");
        }
     } 
     if(socialPosting && socialPosting.available_accounts_error) {
       if(!socialPosting.available_accounts_error.request_status) {
         setMessage("");
         setError(socialPosting.available_accounts_error.object.error);
       }
     }

     if(isCalled && socialPosting && socialPosting.process_account && socialPosting.process_account.object && socialPosting.process_account_success === true) {
        if(null != queryParams.get('code') && socialPosting.process_account_success) {
          setIsCalled(false);
          dispatch(getAvailableAccounts(socialPosting.process_account.object,0));         
        }
     }

     if(socialPosting && socialPosting.available_accounts_error) {
      if(!socialPosting.available_accounts_error.request_status) {
        if(null != queryParams.get('code')) {
           setMessage("");
           setAccountType("ACCOUNTS");
           setError(socialPosting.available_accounts_error.object.error);
        }
      }
    }
  },[socialPosting])

  const onPageChange = (currentPage) => {
    setCurrentPage(currentPage);
    dispatch(getAccounts(totalPages,currentPage))
  };

  const postFeedCall = (accountId, imageId) => {
      history.push(`/social_posts/${accountId}`);
    // dispatch(postFeed(accountId, imageId))
  }

  const deleteFeedCall = (accountId, id) => {
    dispatch(deleteFeed(accountId, id))
  }

  const renderPagination = () => {
    let items = [];
    for (let i = 0; i < totalPages; i++) {
      if (i == currentPage || i == 0 || i == (parseInt(currentPage)-1) || i == (parseInt(currentPage)+1) || i == (totalPages-1)) items.push(
    <Pagination.Item
      key={i}
      active={i == currentPage}
      onClick={() => onPageChange(i)}
      >
      {(i == totalPages-1)?`...${(i+1)}`: i == 0 ?`${(i+1)}...`:(i+1)}
    </Pagination.Item>
    );
    }
    return (
     <Pagination style={{ overflowX: "auto" }}>
     <Pagination.Prev
      disabled={currentPage === 0}
      onClick={() => onPageChange(currentPage - 1)}
     />
    {items}
     <Pagination.Next
      disabled={currentPage === totalPages-1}
      onClick={() => onPageChange(currentPage + 1)}
     />
     </Pagination>
    );
  };
  

  // add data of classes api into constant,hook start
     useMemo(() =>{
       if(socialPosting && socialPosting.connect_account && socialPosting.connect_account.object && socialPosting.connect_account_loading === false){
         window.location.href = socialPosting.connect_account.object;
       }

       
     },[socialPosting])
  // add data of classes api into constant,hook End
  
  const connectAccountCall = () =>{
    setIsLoading(true);
    dispatch(connectAccount());
  }

  const cancelSaveData = () =>{
     setError("");
     setMessage("");
     setAccountType("ALL");
     history.push(`/fb_business_account_authorization`);
  }

  const saveAccountsData = () =>{
    // console.log("checkedItems==>"+checkedItems.length)
    //const array = checkedItems.toString.split(',').map((item) => item.trim());
    setMessage("");
    if(checkedItems.length == 0) {
       setError("please check items!");
    } else {
       setError("");
       setCurrentPage(0);
       if(availableAccounts.length > 0) dispatch(saveAccounts(availableAccounts[0].account_id,checkedItems));
    }
  }
  
  // resetHandler function start
     const resetHandler = (event) =>{
       event.preventDefault()
       window.location.reload(false);
     }
  // resetHandler function start

// add data of allexams api into constant,hook start
   useMemo(() =>{
     if(socialPosting.get_accounts && socialPosting.get_accounts.data && socialPosting.get_accounts.request_status === true){
        setTotalPages(Math.ceil(socialPosting.get_accounts.total / pageSize));
        setAvailableAccounts(socialPosting.get_accounts.data)
     }
   },[socialPosting])
// add data of allexams api into constant,hook End


        return (<>
        <div className="row col-4-xxxl col-12">
            <div className="card height-auto">
              <button type="button" className="btn-fill-lg bg-blue-dark btn-hover-yellow" style={{width:'280px'}} onClick={(e) =>connectAccountCall()}>{isLoading ? <><Loader
                      
                      type="ThreeDots"
                      color="#ffffff"
                      height={30}
                      width={60}

         /></> : "Facebook Connect Account"}</button>
            </div>
        </div>
        {accountType == "ALL" && !queryParams.get('code') && (<div className="row" style={{width:'100%'}}>
            <div className="col-12-xxxl col-lg-12 col-12">
            <div className="card">
                <div className="card-body">

                  { message ?
                    <p className="alert alert-success w-auto" role="alert">
                      {message}
                    </p> : <p></p>
                  }

                  { error ?
                    <p className="alert alert-danger w-auto" role="alert">
                      {error}
                    </p> : <p></p>
                  }

                  <div className="table-box-wrap">

                      <div className="row gutters-8">



                        <div className="col-lg-12 form-group">

                        </div>
                      </div>


                      { (socialPosting && socialPosting.get_accounts_loading === true) ?
                        (<div className="card-body">
                        <div className="Student-Loader">
                        <Loader
                        className = "student-detail-loader"
                      type="ThreeDots"
                      color="#fea801"
                      height={150}
                      width={150}

         />
                        </div>
                      </div>) : (<div className="table-responsive expenses-table-box">

                    <table className="table data-table text-nowrap">
                        <thead>
                          <tr>
                            <th>S.No.</th>
                            <th>Business Name</th>
                            <th>Account ID</th>
                            <th>Social Network</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                        {availableAccounts && availableAccounts.map((item,index)=>{
                          return (
                            <tr>
                               <td>{(index+1)}</td>
                               <td><img src={item.profile_picture} width="60px" height="40px"/> {item.name}</td>  
                               <td><a href={"https://www.facebook.com/profile.php?id=" + item.account_id} target="_blank">{item.account_id}</a></td>
                               <td>{item.type}</td>
                               <td>
                                 <Link
                                   to={{
                                     pathname: '/social_posts',
                                     state: {
                                      accountId: item.account_id,
                                      accountName: item.name,
                                      profile_picture: item.profile_picture,
                                    }, // Pass data via state
                                   }}
                                 >
                                  {/* <Link to={`/social_posts/${item.account_id}/${item.profile_picture}`}> */}
                                  Post</Link>
                               </td>                            
                            </tr>
                          )
                        })}


                        </tbody>
                      </table>
                    </div>)}
                  </div>
                </div>
              </div>
              </div>
              
              <div className='row' style={{width:'100%'}}>
                <div className="col-12-xxxl col-12">     
                   <div style={{ marginTop: '10px' }}>{renderPagination()}</div>  
              
                </div>
             
              </div>
              </div>)}

              {socialPosting && socialPosting.available_accounts_loading === true && <div className="col-12-xxxl col-12">
                <div className="card">
                  <div className="card-body">
                     <div className="card-body">
                        <div className="Student-Loader">
                         <Loader
                           className = "student-detail-loader"
                           type="ThreeDots"
                           color="#fea801"
                           height={150}
                           width={150}/>
                        </div>
                     </div>
                  </div>
                </div>
              </div>}

              {accountType == "ACCOUNTS" && <><div className="col-12-xxxl col-12">
                <div className="card">
                  <div className="card-body">

                  { message ?
                    <p className="alert alert-success w-auto" role="alert">
                      {message}
                    </p> : <p></p>
                  }

                  { error ?
                    <p className="alert alert-danger w-auto" role="alert">
                      {error}
                    </p> : <p></p>
                  }

{socialPosting && socialPosting.save_account_loading === true ?
                        (<div className="card-body">
                        <div className="Student-Loader">
                        <Loader
                        className = "student-detail-loader"
                      type="ThreeDots"
                      color="#fea801"
                      height={150}
                      width={150}

         />
                        </div>
                      </div>) : 
                  <div className="table-responsive expenses-table-box">

<table className="table data-table text-nowrap">
    <thead>
      <tr>
        <th></th>
        <th>S.No.</th>
        <th>Account ID</th>
        <th>Profile Image</th>
        <th>Name</th>
      </tr>
    </thead>
    <tbody>
    {accounts && accounts.map((item,index)=>{
      return (
        <tr>
           <td><input type="checkbox" className="checkbx" checked={checkedItems.includes(item.id)}
              onChange={handleCheckboxChange(item.id)}/></td>
           <td>{(index+1)}</td>
           <td>{item.id}</td>       
           <td><img src={item.profile_picture} width="60px" height="40px"/></td>  
           <td>{item.name}</td>                    
        </tr>
      )
    })}


    </tbody>
  </table>
</div>}

                  </div>
                </div>
              </div> 
              
                
                   <div className="col-12-xxxl col-12">  
                       <button type="button" className="btn-fill-lg bg-blue-dark btn-hover-yellow right-float mg-l-10" onClick={(e) =>saveAccountsData()}>Save Account</button>
                       <button type="button" className="btn-fill-lg bg-blue-dark btn-hover-yellow right-float" onClick={(e) =>cancelSaveData()}>Cancel</button>
                   </div>
                
              </>

              }

            </>
        );
}

export default AvailableAccounts;
