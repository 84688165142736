export const CONNECT_ACCOUNT_REQUEST = 'CONNECT_ACCOUNT_REQUEST'
export const CONNECT_ACCOUNT_SUCCESS = 'CONNECT_ACCOUNT_SUCCESS'
export const CONNECT_ACCOUNT_FALIURE = 'CONNECT_ACCOUNT_FALIURE'
export const PROCESS_ACCOUNT_REQUEST = 'PROCESS_ACCOUNT_REQUEST'
export const PROCESS_ACCOUNT_SUCCESS = 'PROCESS_ACCOUNT_SUCCESS'
export const PROCESS_ACCOUNT_FALIURE = 'PROCESS_ACCOUNT_FALIURE'
export const GET_ACCOUNTS_REQUEST = 'GET_ACCOUNTS_REQUEST'
export const GET_ACCOUNTS_SUCCESS = 'GET_ACCOUNTS_SUCCESS'
export const GET_ACCOUNTS_FALIURE = 'GET_ACCOUNTS_FALIURE'
export const POST_FEED_REQUEST = 'POST_FEED_REQUEST'
export const POST_FEED_SUCCESS = 'POST_FEED_SUCCESS'
export const POST_FEED_FALIURE = 'POST_FEED_FALIURE'
export const DELETE_FEED_REQUEST = 'DELETE_FEED_REQUEST'
export const DELETE_FEED_SUCCESS = 'DELETE_FEED_SUCCESS'
export const DELETE_FEED_FALIURE = 'DELETE_FEED_FALIURE'
export const GET_FEED_REQUEST = 'GET_FEED_REQUEST'
export const GET_FEED_SUCCESS = 'GET_FEED_SUCCESS'
export const GET_FEED_FALIURE = 'GET_FEED_FALIURE'
export const AVAILABLE_ACCOUNTS_REQUEST = 'AVAILABLE_ACCOUNTS_REQUEST'
export const AVAILABLE_ACCOUNTS_SUCCESS = 'AVAILABLE_ACCOUNTS_SUCCESS'
export const AVAILABLE_ACCOUNTS_FALIURE = 'AVAILABLE_ACCOUNTS_FALIURE'
export const RESET_REQUEST = 'RESET_REQUEST'
export const SAVE_ACCOUNT_REQUEST = 'SAVE_ACCOUNT_REQUEST'
export const SAVE_ACCOUNT_SUCCESS = 'SAVE_ACCOUNT_SUCCESS'
export const SAVE_ACCOUNT_FALIURE = 'SAVE_ACCOUNT_FALIURE'
export const RESET_ACCOUNTS = 'RESET_ACCOUNTS'